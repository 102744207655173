<template>
  <div class="select-material">
    <div
      class="materials1"
      v-if="$route.query.from == 'select'"
    >
      <div
        class="material1"
        v-for="(i, j) in selectMaterialList"
        :key="j"
        @click="(val) => checkboxChange(val, i, j)"
      >
        <div class="icon">
          <span class="icon-container">
            <img
              class="icon-container-img"
              :src="i.image"
            />
          </span>
        </div>
        <div class="info">
          <div class="name">{{i.name}}</div>
          <div class="id">ID：{{i.showId && i.showId != '0' ? i.showId : i.uniqueCode }}</div>
        </div>
        <van-checkbox
          :value="i.checked"
          checked-color="#ffffff"
          color="#2c2c2"
        ></van-checkbox>
      </div>
      <div
        style="z-index:1999;"
        class="btns row-center"
      >
        <p class="need-amount">共需{{needAmount}}份</p>
        <div
          class="completed-pay"
          @click="confirmComposite"
        >确认选择</div>
      </div>
    </div>
    <div
      class="materials2"
      v-else
    >
      <div
        class="material2"
        v-for="(i, j) in orderInfo.mergeTokenOrder.destroyAssetList"
        :key="j"
        @click="toCompositeRecordDetail(i)"
      >
        <div class="icon">
          <span class="icon-container">
            <img
              class="icon-container-img"
              :src="i.image"
            />
          </span>
        </div>
        <div class="collection-content">
          <p class="collection-item-name">{{ i.name }}</p>
          <div class="row-start my-collection-i-id"> ID:{{i.showId  && i.showId != '0'? i.showId : i.uniqueCode}}</div>
          <div class="row-between">
            <p
              class="collection-facName"
              style=""
            >{{ i.facName }}</p>
            <img
              style="width: 1rem;height: auto;margin-right: 0.625rem;"
              :src="require(`../assets/icon/media/${mediaType[i.cateId] ? mediaType[i.cateId] : 'img'}.png`)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api/index-client'
import Vue from 'vue'
import config from '../config'
import { Checkbox,CheckboxGroup,PasswordInput,NumberKeyboard,Button } from 'vant';
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
export default {
  data () {
    return {
      selectMaterialList: [],
      selectUnicode: [],
      mediaType: config.mediaType,
      selectIndex: null,
      needAmount: null,
      orderInfo: {
        mergeTokenOrder: {
          destroyAssetList: []
        }

      }
    }
  },
  mounted () {
    this.$store.commit('HIDE_APPLOADING')
    sessionStorage.removeItem('selectUnicode')
    if (this.$route.query.from == 'select') {
      this.selectMaterialList = JSON.parse(sessionStorage.getItem('selectMaterialList'))
      var arr1 = [],skuNo = JSON.parse(sessionStorage.getItem('selectMaterialList'))[0].skuNo
      this.needAmount = (JSON.parse(sessionStorage.getItem('selectMaterialListMulti'))[0].filter(i => i.skuNo == skuNo)).length * sessionStorage.getItem('value')
      JSON.parse(sessionStorage.getItem('selectMaterialListMulti')).map(i => {
        i.map(j => {
          if (j.skuNo == skuNo) {
            arr1.push(j)
          }
        })
      })
      this.selectMaterialList.map(i => {
        if (arr1.filter(j => j.uniqueCode == i.uniqueCode).length) {
          i.checked = true
        }
      })
    } else {
      this.getData()
    }
  },
  beforeDestroy () {
    sessionStorage.setItem('selectUnicodeLength',true)
  },
  methods: {
    getSelectList () {
      this.selectUnicode = this.selectMaterialList.filter(i => i.checked)
      sessionStorage.setItem('selectUnicode',JSON.stringify(this.selectUnicode))
    },
    checkboxChange (val,item,index) {
      if ((this.selectMaterialList.filter(i => i.checked).length >= this.needAmount) && !item.checked) {
        return this.$toast({
          message: '所选藏品数量已超过所需材料数量',
          icon: require('../assets/icon/toast-error.png'),
        });
      }
      item.checked = !item.checked

      this.getSelectList()
      if (item.checked) {
        this.selectIndex = index
      } else {
        this.selectIndex = null
      }
      this.$forceUpdate()

    },
    getData () {
      api
        .get(`mall/mergetoken/order/${this.$route.query.orderId}`)
        .then(result => {
          if (result.data.success) {
            this.orderInfo = result.data.data

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    toCompositeRecordDetail (i) {
      i.submitTime = this.orderInfo.mergeTokenOrder.submitTime
      sessionStorage.setItem('materialInfo',JSON.stringify(i))
      this.$router.push('/compositeRecordDetail')
    },
    // 确认选择
    confirmComposite () {

      if (this.selectMaterialList.filter(i => i.checked).length != this.needAmount) {
        this.$toast({
          message: "当前所选数量不满足合成所需数量",
          icon: require('../assets/icon/toast-error.png'),
        });
      } else {
        let index = 0,skuNo = JSON.parse(sessionStorage.getItem('selectMaterialList'))[0].skuNo,arr1 = JSON.parse(sessionStorage.getItem('selectMaterialListMulti'))
        arr1.map(i => {
          i.map(m => {
            if (m.skuNo == skuNo) {
              m.uniqueCode = this.selectMaterialList.filter(i => i.checked)[index].uniqueCode
              index++
            }
          })
        })
        if (index == (this.selectMaterialList.filter(i => i.checked).length)) {
          sessionStorage.setItem('selectMaterialListMulti',JSON.stringify(arr1))
          sessionStorage.setItem('selectUnicode',JSON.stringify(this.selectMaterialList.filter(i => i.checked)))
          this.$router.go(-1);
        }
      }

    }
  }
}
</script>

<style lang="less" scoped>
.select-material {
  margin-top: 3.875rem;
  margin-bottom: 3.875rem;
  .materials1 {
    // padding-top: 4.8125rem;
    margin: 4.8125rem 0.75rem 0;
    background: #2c2c2c;

    .material1 {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 1rem 0 1.25rem 0;
      border-bottom: 1px solid #3f3f3f;
      margin: 0 1.3125rem 0 0.8125rem;
      position: relative;
      &:last-child {
        border-bottom: 0;
      }
      .icon {
        width: 5rem;
        height: 5rem;
        flex-grow: 0;
        background: #d8d8d8;
        border-radius: 0.5rem;
        overflow: hidden;
        &-container {
          width: 5rem;
          height: 5rem;
          display: block;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
      .info {
        flex-grow: 1;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        position: relative;
        margin-left: 0.9375rem;
        height: 4.0625rem;
        padding-top: 0.9375rem;
        .name {
          font-size: 1.125rem;
          font-weight: normal;
          color: #ffffff;
          margin: 0px 0 0.5626rem;
        }
        .id {
          font-size: 0.75rem;
          font-weight: normal;
          color: #999999;
        }
      }
      .van-checkbox {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        /deep/.van-icon {
          // color: #1c1c1c !important;
        }
        // .van-icon
      }
    }
  }
  .materials2 {
    margin: 0 5%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .material2 {
      border-radius: 4px;
      overflow: hidden;
      width: 42vw;
      margin-bottom: 10px;
      .icon {
        width: 100%;
        height: 42vw;
        background: #d0d0d0;
        &-container {
          width: 100%;
          height: 42vw;
          display: block;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
      .info {
        height: 5rem;
        background: #3c3c3c;
      }
      .collection-content {
        // height: 2.5625rem;
        background: #3c3c3c;
        padding: 0.5rem 0;
      }
      .collection-content1 {
        // height: 2.5625rem;
        background: #3c3c3c;
        padding: 0.5rem 0;
      }
      .collection-img {
        width: 100%;
        height: auto;

        display: block;
      }
      .collection-facName {
        margin-left: 0.6875rem;
        color: #c6c3c2;
        font-size: 0.75rem;
      }
      .collection-item-name {
        font-size: 1rem;
        font-weight: 500;
        color: #ffffff;
        height: 1.25rem;
        line-height: 1.25rem;
        margin-left: 0.6875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .my-collection-i-id {
        margin: 0.3125rem 0.6875rem 0.5625rem 0.6875rem;
        // padding-left: 0.4rem;
        height: auto;
        // padding: 0.625rem;
        font-size: 0.75rem;
        font-weight: normal;
        color: #c6c3c2;
        line-height: 1.0625rem;
        // background: #f2f2f2;
        border-radius: 0.125rem;
        word-break: break-all;
      }
    }
  }
}
.btns {
  // width: 375px;
  height: 4rem;
  background: #080808;
  padding-top: 0.4375rem;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  .need-amount {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #a4a4a4;
    margin-right: 20px;
  }
  .completed-pay {
    width: 60%;
    height: 2.875rem;
    margin-bottom: 0 !important;
    line-height: 2.875rem;
    backdrop-filter: blur(1.1875rem);
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    color: #ffffff;
  }
}
</style>
import { render, staticRenderFns } from "./CompositeSelectMaterial.vue?vue&type=template&id=5959aa11&scoped=true"
import script from "./CompositeSelectMaterial.vue?vue&type=script&lang=js"
export * from "./CompositeSelectMaterial.vue?vue&type=script&lang=js"
import style0 from "./CompositeSelectMaterial.vue?vue&type=style&index=0&id=5959aa11&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5959aa11",
  null
  
)

export default component.exports